function Footer() {
    return (
        <footer>
            <div id="footerOverlay">
                <p>
                    Copyright &copy; {new Date().getFullYear()} Josh L. Rogers. All rights reserved. <span style={{display:"none"}}>{process.env.NODE_ENV}</span>
                </p>
            </div>
        </footer>
    );
}

export default Footer;